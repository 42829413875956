import { Header, NavLink, NoRoleDisclaimer, Toast, useToast } from "@cd/sdds-common-components-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { RBACWrapper } from "react-simple-rbac"
import { useAppSelector } from "../redux/store"
import { UserContext } from "../appContext"
import { getSupportedRolesForSections } from './../helpers/sectionRoleMapper';
import GuidedTroubleshoutingTour from "../components/GuidedTroubleshoutingTour"


const Index = () => {

	const { t } = useTranslation();
	const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);
	const { selectedCustomer, hasError, errorMessage } = useAppSelector<SearchState>(state => state.universalSearch)
	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	const [searchStatus, setSearchStatus] = useState<boolean>(false);
	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	const [searchResult, setSearchResult] = useState<string>('');
	const [scaniaSignedAndRead, setScaniaSignedAndRead] = useState<boolean>(true);
	const currentApp = 'Guided Troubleshooting';

	const { toastQueue, addToast } = useToast()


	useEffect(() => {
	if (hasError && errorMessage !== '') {
		addToast({
		title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:UnExpectedErrorMessage"),
		type: "error"
		})
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasError, errorMessage]);

	useEffect(() => {
		if ((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) ||
            ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) {
            setScaniaSignedAndRead(true)
        } else {
            setScaniaSignedAndRead(false);
        }
	}, [selectedCustomer, selectedCustomer?.scaniaLegalDocumentationSigned, selectedCustomer?.scaniaRead, loggedInProfileInfo?.role]);

	return (
		<>
		 {(!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
			<>
			<NoRoleDisclaimer />
			</>
		  ) : 
		 (
		  <>
		   <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}>
			<Header
				profileInfo={loggedInProfileInfo}
				currentApp={currentApp}
				setSearchComplete={setSearchStatus}
				setSearchStatus={setSearchResult}
				equipmentRedirectPath={'service-troubleshooting'}
				tours={[<GuidedTroubleshoutingTour loggedInProfileInfo={loggedInProfileInfo} />]}
			>
				<RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Service Troubleshooting')}>
					<NavLink path="/service-troubleshooting" text={t('TS_core:ServiceTroubleshooting')} isScaniaRead={!scaniaSignedAndRead} uniqueId={"ServiceTroubleshootingSignedAndRead"} idd="serviceTroubleshooting_Tour_Id" />
				</RBACWrapper>
				<RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Package Management')}>
					<NavLink path="/package-management" text="Package Management" idd="packageManagement_Tour_Id" />
				</RBACWrapper>
				<RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Function Management')}>
					<NavLink path="/function-management" text="Function Management" idd="functionManagement_Tour_Id" />
				</RBACWrapper>
				<RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Fault Guide Management')}>
					<NavLink path="/fault-guide-management" text={t('TS_temp:FaultGuide')} idd="faultGuide_Tour_Id" />
				</RBACWrapper>
			</Header>
			</RBACWrapper>
			<RBACWrapper oneOf requiredRoles={['Admin']}>
				<div className="documentation-bar sdds-body-02"> 
					<pre className='sdds-body-02'>
						<a target="_blank" rel="noreferrer noopener" href="https://gitlab.scania.com/troubleshooting/docs-documentation/-/blob/master/TS_FUNCTIONS_DEVELOPER_GUIDE.md?ref_type=heads#getting-started-with-troubleshooting-functions-developer-guide">Quick guide</a> to enable troubleshooting for your functions on CSTT 
					</pre>					
				</div>
			</RBACWrapper>
			<Outlet />
			</>
			 ) 
			}
			<Toast toastQueue={toastQueue} />
		</>
	)
}

export default Index